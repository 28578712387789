import styled from 'styled-components'
import { ColumnWrapper } from '../styled-components/Wrappers.styled'

export const TileParagraph = styled.p`
    font-family: 'Futura T OT Medium';
    font-size: 1.6rem;
    color: ${({ inverted }) =>
        inverted ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'};
    align-self: flex-start;
    line-height: 1.5;
    margin: 3rem 0 3rem;
`
export const TileTextContainer = styled(ColumnWrapper)`
    margin: ${({ inverted }) => (inverted ? '0 auto 0 0' : '0 0 0 auto')};
    padding: ${({ inverted }) => (inverted ? '0 0 0 4rem' : '0 4rem 0 0')};

    letter-spacing: 0.5px;
    z-index: 10;

    @media (max-width: 1320px) {
        margin: 0;
        padding: 3rem;
    }

    @media (max-width: 960px) {
        min-height: 300px;
        padding: 3rem;
    }
`
