import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

const renderImage = file => <Img fixed={file.node.childImageSharp.fixed} />

const MyImg = function (props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    images: allFile(
                        filter: {
                            extension: { regex: "/jpeg|jpg|png|gif/" }
                            relativeDirectory: { eq: "opinion-photos" }
                        }
                    ) {
                        edges {
                            node {
                                extension
                                relativePath
                                relativeDirectory
                                childImageSharp {
                                    fixed(width: 60, height: 60) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={({ images }) =>
                renderImage(
                    images.edges.find(
                        image =>
                            image.node.relativePath ===
                            `${image.node.relativeDirectory}/${props.src}`
                    )
                )
            }
        />
    )
}
export default MyImg
