import React from 'react'
import { Link } from 'gatsby'
import { TileParagraph } from './TileSection.styled'
import {
    TileUpperTitle,
    TileLowerTitle,
} from '../styled-components/Headings.styled'
import Button from '../styled-components/Button'
import { TileTextContainer } from './TileSection.styled'

const TileText = ({ section }) => {
    return (
        <TileTextContainer
            width="630px"
            height="100%"
            inverted={section.inverted}
        >
            <TileUpperTitle>{section.upperTitle}</TileUpperTitle>
            <TileLowerTitle>{section.lowerTitle}</TileLowerTitle>
            <TileParagraph
                inverted={section.inverted}
                dangerouslySetInnerHTML={{ __html: section.content }}
            />
            {section.button && section.href ? (
                <Button
                    style={{
                        alignSelf: 'flex-start',
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    as="a"
                    href={section.href}
                    target="_blank"
                    rel="noreferrer"
                >
                    {section.button}
                </Button>
            ) : null}
            {section.button && section.link ? (
                <Button
                    style={{
                        alignSelf: 'flex-start',
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    as={Link}
                    to={section.link}
                >
                    {section.button}
                </Button>
            ) : null}
        </TileTextContainer>
    )
}

export default TileText
