import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/SEO'
import WelcomePage from '../components/WelcomePage'
import Opinions from '../components/opinions/Opinions'
import TileSectionsBlock from '../components/tiles/TileSectionsBlock'
import MenuSection from '../components/MenuSection'

const Index = () => {
    return (
        <Layout headerMode="landing">
            <SEO title="Bez cukru, bez pszenicy" />
            <WelcomePage />
            <MenuSection />
            <TileSectionsBlock />
            <Opinions />
        </Layout>
    )
}

export default Index
