import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import TileSection from './TileSection'

const TileSectionsBlock = () => {
    const data = useStaticQuery(graphql`
        query {
            allTileSectionsJson {
                nodes {
                    id
                    alt
                    inverted
                    backgroundColor
                    name
                    outlineTitle
                    upperTitle
                    lowerTitle
                    content
                    button
                    href
                    link
                    src {
                        childImageSharp {
                            fluid(maxWidth: 960) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)
    return data.allTileSectionsJson.nodes.map(section => (
        <TileSection key={section.id} section={section} />
    ))
}

export default TileSectionsBlock
