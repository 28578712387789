const opinions = [
    {
        id: 1,
        name: 'Paula',
        imgSrc: 'opinion-photo-1.png',
        opinion:
            'Znakomite jedzenie za rozsądną cenę. Dużym plusem jest menu, które informuje o składzie i kaloryczności zamawianych posiłkow. Dostępne opcje bezglutenowe, bezlaktozowe i roślinne. Miła obsługa i przyjemny wystrój.',
    },
    {
        id: 2,
        name: 'Venessa',
        imgSrc: 'opinion-photo-2.png',
        opinion:
            'Obsługa bardzo mila, a menu bardzo zdrowe i smaczne :) Świetnie, że ktoś na diecie bezglutenowej, lub wege/wegan też znajdzie coś dla siebie na śniadanie :) podoba mi się wystrój, nieco mimalistyczny, prosty.',
    },
    {
        id: 3,
        name: 'Adam',
        imgSrc: 'opinion-photo-3.png',
        opinion:
            'Przyjemny lokal na ciche spotkania z drugą połówką 😇 Smaczne desery oraz herbatka! Polecam gorąco!',
    },
]

export default opinions
