import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { RowWrapper, ColumnWrapper } from './styled-components/Wrappers.styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Section from './Section'
import { H3wBackFill } from './styled-components/Headings.styled'

const StyledMenuContainer = styled(ColumnWrapper)`
    position: relative;

    @media (max-width: ${({ theme }) => theme.media.phone}) {
        height: 150px;
    }
`
const MenuImageContainer = styled(RowWrapper)`
    width: 50vw;
    height: 100%;
    display: block;
    overflow: hidden;
    align-items: center;
    position: relative;
`

const StyledHeadingContainer = styled(RowWrapper)`
    position: absolute;
    top: 0;
    ${({ left }) => left && 'right: 0'};
    ${({ right }) => right && 'left: 0'};

    height: 100%;
    width: 630px;
    padding: 5rem;
    justify-content: ${({ left }) => left && 'flex-end'};
    justify-content: ${({ right }) => right && 'flex-start'};

    align-items: flex-end;

    @media (max-width: ${({ theme }) => theme.media.phone}) {
        justify-content: center;
        align-items: center;
        padding: 0;
        h3 {
            position: absolute;
            border-radius: 0;
            width: 100%;
            font-size: 2rem;
            bottom: 0;
        }
    }
`
const StyledImg = styled(Img)`
    height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.9;
`

const MenuSection = () => {
    const data = useStaticQuery(graphql`
        query {
            left: file(
                relativePath: { eq: "menu-section/menu-image-breakfast.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            right: file(
                relativePath: { eq: "menu-section/menu-image-sweets.jpg" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return (
        <Section outlineTitle="Menu" id="menu-section">
            <StyledMenuContainer height="360px" margin="0">
                <RowWrapper width="100%" height="100%" toColumnWidth="0">
                    <MenuImageContainer>
                        <StyledHeadingContainer left>
                            <H3wBackFill primary style={{ color: 'black' }}>
                                na śniadanie
                            </H3wBackFill>
                        </StyledHeadingContainer>
                        <StyledImg fluid={data.left.childImageSharp.fluid} />
                    </MenuImageContainer>

                    <MenuImageContainer>
                        <StyledHeadingContainer right>
                            <H3wBackFill>na słodkie</H3wBackFill>
                        </StyledHeadingContainer>
                        <StyledImg fluid={data.right.childImageSharp.fluid} />
                    </MenuImageContainer>
                </RowWrapper>
            </StyledMenuContainer>
        </Section>
    )
}

export default MenuSection
