import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import ScrollUpSvg from '../images/arrow-icon.svg'
import styled from 'styled-components'

const StyledScrollDownButton = styled.button`
    position: relative;
    bottom: 100px;
    margin: auto;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    z-index: 1;
    svg {
        display: block;
        height: 30px;
        width: 100%;
        stroke: rgba(0, 0, 0, 0.7);
        stroke-width: 0.5;
        fill: rgba(255, 255, 255, 1);
    }
    @media (max-width: ${({ theme }) => theme.media.phone}) {
        height: 60px;
        width: 60px;
    }
`

const ScrollDownButton = ({ anchorId }) => {
    return (
        <StyledScrollDownButton
            id="button-scroll_content"
            as={AnchorLink}
            to={`/${anchorId}`}
        >
            <ScrollUpSvg
                aria-label="Scroll down to see page content"
                alt="Arrow down"
            />
        </StyledScrollDownButton>
    )
}

export default ScrollDownButton
