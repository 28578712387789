import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { RowWrapper } from '../styled-components/Wrappers.styled'

const TileImageContainer = styled(RowWrapper)`
    width: 50%;
    min-height: 100%;
    display: block;
    overflow: hidden;
    align-items: center;

    @media (max-width: 960px) {
        height: 300px;
        width: 100vw;
    }
`

const TileImage = ({ section }) => {
    return (
        <TileImageContainer>
            <Img
                style={{
                    position: 'relative',
                    top: '50%',
                    height: '100%',
                    transform: 'translateY(-50%)',
                    opacity: 1,
                }}
                fluid={section.src.childImageSharp.fluid}
                alt={section.alt}
            />
        </TileImageContainer>
    )
}

export default TileImage
