import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, data, justify, backgroundColor }) => {
    const imageData = data.childImageSharp.fluid
    return (
        <BackgroundImage
            Tag="div"
            className={className}
            fluid={imageData}
            backgroundColor={backgroundColor}
            justify={justify}
        />
    )
}
const StyledBackgroundSection = styled(BackgroundSection)`
    height: 100%;
    width: 100%;
    background-size: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 0;
`

export default StyledBackgroundSection
