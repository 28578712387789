import React from 'react'
import styled from 'styled-components'

const StyledSection = styled.section`
    position: relative;
    h2 {
        display: none;
    }
`

const Section = ({ id, outlineTitle, children, style }) => {
    return (
        <StyledSection id={id} style={style}>
            <h2>{outlineTitle}</h2>
            {children}
        </StyledSection>
    )
}

export default Section
