import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Branding from './layout/Branding'
import useWindowWidth from '../utils/hooks/useWindowWidth'
import theme from '../utils/theme'
import ScrollDownButton from './ScrollDownButton'

const WelcomeWrapper = styled.section`
    width: 100%;
    height: 100vh;
    position: relative;
`

const WelcomePage = () => {
    const data = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "jaglana-home.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobile: file(relativePath: { eq: "jaglana-home-mobile.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 768) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    const width = useWindowWidth()
    const [isScreenMobile, setIsScreenMobile] = useState(null)
    useEffect(() => {
        width <= parseInt(theme.media.phone)
            ? setIsScreenMobile(true)
            : setIsScreenMobile(false)
    }, [width])
    return (
        <WelcomeWrapper id="welcome-section">
            <h2 style={{ display: 'none' }}>Strona powitalna</h2>
            <Branding welcome={1} pulse={1} />
            {isScreenMobile ? (
                <Img
                    loading="eager"
                    fluid={data.mobile.childImageSharp.fluid}
                    alt="Zdjęcie ukazujące lokal Jaglana z perspektywy ulicy Kramarskiej"
                    style={{ display: 'inherit', height: '100%' }}
                />
            ) : (
                <Img
                    loading="eager"
                    fluid={data.desktop.childImageSharp.fluid}
                    alt="Zdjęcie ukazujące lokal Jaglana z perspektywy ulicy Kramarskiej"
                    style={{ display: 'inherit', height: '100%' }}
                />
            )}
            <ScrollDownButton anchorId="#menu-section" />
        </WelcomeWrapper>
    )
}

export default WelcomePage
