import React from 'react'
import {
    OpinionCardWrapper,
    OpinionCardPhotoWrapper,
    OpinionCardName,
    OpinionCardText,
    OpinionCardRatingWrapper,
} from './Opinions.styled'
import OpinionPhoto from './OpinionPhoto'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const OpinionCard = ({ opinion: { name, imgSrc, opinion } }) => {
    const data = useStaticQuery(graphql`
        query {
            stars: file(relativePath: { eq: "opinion-photos/stars.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <OpinionCardWrapper width="320px" shadow>
            <OpinionCardPhotoWrapper>
                <OpinionPhoto src={imgSrc} />
            </OpinionCardPhotoWrapper>
            <OpinionCardName>{name}</OpinionCardName>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <OpinionCardText>„{opinion}”</OpinionCardText>
                <OpinionCardRatingWrapper>
                    <Img fluid={data.stars.childImageSharp.fluid} />
                </OpinionCardRatingWrapper>
            </div>
        </OpinionCardWrapper>
    )
}

export default OpinionCard
