import React from 'react'
import styled from 'styled-components'
import { RowWrapper } from '../styled-components/Wrappers.styled'
import TileText from './TileText'
import TileImage from './TileImage'

const StyledTileSection = styled.section`
    display: flex;
    height: 540px;
    position: relative;
    width: 100%;

    h2 {
        display: none;
    }

    @media (max-width: 960px) {
        flex-direction: column !important;
        height: auto;
    }
`

const TileSection = ({ section }) => {
    return section.inverted ? (
        <StyledTileSection id={`${section.name}-section`}>
            <h2>{section.outlineTitle}</h2>
            <TileImage section={section} />
            <RowWrapper
                width="50%"
                height="100%"
                toColumnWidth="960px"
                margin="0 auto 0"
            >
                <TileText section={section} />
            </RowWrapper>
        </StyledTileSection>
    ) : (
        <StyledTileSection
            id={`${section.name}-section`}
            style={{ flexDirection: 'row-reverse' }}
        >
            <TileImage section={section} />
            <h2>{section.outlineTitle}</h2>
            <RowWrapper
                width="50%"
                minHeight="inherit"
                toColumnWidth="960px"
                margin="0 auto 0"
                background={section.backgroundColor}
            >
                <TileText section={section} />
            </RowWrapper>
        </StyledTileSection>
    )
}

export default TileSection
