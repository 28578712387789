import styled from 'styled-components'
import { ColumnWrapper } from '../styled-components/Wrappers.styled'

export const H2Section = styled.h2`
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.4rem;
    font-family: 'Futura T OT Medium';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    margin: 20px 0 20px;
`

export const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.media.phone}) {
        flex-direction: column;
    }
`

export const OpinionCardWrapper = styled(ColumnWrapper)`
    min-height: auto;
    justify-content: flex-start;
    padding: 40px 30px 30px;
    border-bottom: 10px rgba(0, 0, 0, 0.4) solid;
    background-color: white;
    z-index: 1;
    margin: 50px 20px 50px;
    @media (max-width: ${({ theme }) => theme.media.phone}) {
        margin: 30px auto 30px;
        flex-direction: column;
    }
`
export const OpinionCardPhotoWrapper = styled.div`
    margin: 20px auto 20px;
`
export const OpinionCardName = styled.h3`
    width: 100%;
    padding: 12px;
    margin: 20px 0 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.8;
    font-family: 'Futura T OT';
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
`

export const OpinionCardText = styled.p`
    font-size: 1.6rem;
    margin: 20px 10px 20px;
    text-align: center;
    letter-spacing: 0.5;
    line-height: 1.4;
    font-family: 'Futura T OT';
    color: rgba(0, 0, 0, 0.6);
`

export const OpinionCardRatingWrapper = styled.div`
    margin: 20px auto 20px;
    width: 60%;
`
