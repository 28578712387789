import React from 'react'
import OpinionCard from './OpinionCard'
import { ColumnWrapper } from '../styled-components/Wrappers.styled'
import { H2Section, CardContainer } from './Opinions.styled'
import opinions from './opinions-list'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from '../BackgroundImage'

const Opinions = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "opinions-background.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return (
        <section id="opinions-section" style={{ position: 'relative' }}>
            <BackgroundImage data={data.file} />
            <ColumnWrapper
                width="1260px"
                height="1080px"
                background="transparent"
                margin="0 auto 0"
                padding="3rem"
            >
                <H2Section>Jaglana według Was</H2Section>
                <CardContainer>
                    {opinions.map(opinion => (
                        <OpinionCard key={opinion.id} opinion={opinion} />
                    ))}
                </CardContainer>
            </ColumnWrapper>
        </section>
    )
}

export default Opinions
